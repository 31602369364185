import * as React from 'react'
import * as css from './my-portal.module.css'
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react'
import { graphql, useStaticQuery } from 'gatsby'
import DefaultLayout from 'layouts/Default'
import LoadingLayout from 'layouts/Loading'
import HeroWithBg from 'components/common/HeroWithBg'
import SanityBlock from 'components/common/SanityBlock'
import { useSso } from 'hooks/useSso'
import newtab from 'utils/newtab'
import { MdKeyboardArrowRight } from 'react-icons/md'

const App = () => {
  const { sanityMyPortalPg: pg } = useStaticQuery(query)
  const { user } = useAuth0()
  const { url } = useSso(pg.hero.title, user)

  return (
    <DefaultLayout {...pg.seo}>
      <HeroWithBg title={pg.hero.title} />

      <section className="container padding">
        <div className={!!pg.video.url ? 'grid' : ''}>
          <div className="richtext">
            <SanityBlock body={pg.hero._rawDescription} />
            <div>
              <a className="link with-icon" href={url} {...newtab}>
                Open {pg.hero.title}
                <MdKeyboardArrowRight />
              </a>
            </div>
          </div>

          {!!pg.video.url && (
            <div className="richtext text-center">
              <h2 className="h3">{pg.video.title}</h2>
              <iframe
                className={css.iframe}
                src={pg.video.url}
                frameBorder="0"
                title={pg.video.title}
                loading="lazy"
              />
            </div>
          )}
        </div>
      </section>
    </DefaultLayout>
  )
}

const MyPortalPg = withAuthenticationRequired(App, {
  onRedirecting: () => <LoadingLayout />
})

export default MyPortalPg

const query = graphql`query MyPortalPg {
  sanityMyPortalPg {
    hero { ...hero }
    video {
      title
      url
    }
    seo {
      title
      description
    }
  }
}`
